.about-screen {
    background-color: black;
    color: white;
    padding: 20px;
    font-weight: bold;
    min-height: 100vh;
    display: block;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
}

.about-content {
    max-width: 800px;
    margin: 0 auto;
}

.about-screen h1 {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 30px;
}

.about-screen p {
    text-align: justify;
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
}