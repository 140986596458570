.privacy-policy-screen {
    background-color: black;
    color: white;
    padding: 20px;
    text-align: left;
    font-weight: bold;
    min-height: 100vh;
    display: block;
}

.privacy-policy-screen h1,
.privacy-policy-screen h2,
.privacy-policy-screen p,
.privacy-policy-screen ul {
    margin-left: 20px;
}

.privacy-policy-screen ul {
    list-style: none;
    padding: 0;
}

.privacy-policy-screen ul li {
    margin-bottom: 10px;
}
