.contact-screen {
    background-color: black;
    color: white;
    padding: 20px;
    text-align: left;
    font-weight: bold;
    min-height: 100vh;
    display: block;
}

.contact-screen h1,
.contact-screen p {
    margin-left: 20px;
}
