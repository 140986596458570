.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: black; /* Matches your existing dark theme */
    color: white;
    display: flex; /* Use flexbox to align children elements */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 17px 0;
}

.footer a {
    color: white;
    padding: 0 15px; /* Adjust padding as needed */
    text-decoration: none;
}
